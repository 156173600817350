import {getList, save, update, remove, deleteStudentFile} from "@/api/exp/StudentApi";
import {CommonModel} from "@/model/CommonModel";
import axios from "axios";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";
import {msg_confirm, msg_err, msg_success} from "@/utils/ele_component";

/**
 学生model
 */
class StudentModel {
    // 获取列表
    static async getList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getList(params)
        if (size === 0) {  // 不分页
            return data.data;
        } else { // 分页
            let list = CommonModel.generateListMybatisPlus(data.data)
            // 遍历config转换为对象
            let listData = list[0]
            return [listData, list[1]];
        }
    }

    // 新增
    static async save(entity) {
        let [res] = await save(entity)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 修改
    static async update(entity) {
        let [res] = await update(entity)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 删除
    static async remove(idArray, clazzId) {
        let [res] = await remove({
            studentids: idArray,
            clazzid: clazzId
        })
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 删除学生档案
    static async deleteStudentFile(studentid) {
        let [res] = await deleteStudentFile(studentid)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 导出某个班级的全部学生excel
    static export(clazzId, search, administrationClazzId) {
        if (!search) {
            search = "null"
        }
        if (!administrationClazzId) {
            administrationClazzId = "null"
        }
        const url = API_URL_EXP_CONSUMER + `consumer/student/export/student/information/${clazzId}/${search}/${administrationClazzId}`
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "学生列表.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    // 导入学生
    static import(file, clazzId, schoolId) {
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('multipartFile', file)
            axios.create({
                baseURL: API_URL_EXP_CONSUMER
            }).request({
                url: `consumer/clazz/importStudent?clazzId=${clazzId}&schoolId=${schoolId}`,
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data'},
                data: formData
            }).then(response => {
                console.log(response)
                if (response.status === 200) {
                    if (response.data.code === 20000) {
                        resolve(true)
                    } else {
                        msg_confirm(response.data.msg,"导入失败")
                        resolve(false)
                    }
                } else {
                    msg_confirm(response.data.msg,"导入失败")
                    resolve(false)
                }
            })
        })
    }
}

export {StudentModel}
