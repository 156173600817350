import {getList, conditionQueryList, getOne} from "@/api/exp/AdministrationClazzApi";
import {CommonModel} from "@/model/CommonModel";

/**
 行政班model
 */
class AdministrationClazzModel {
    // 获取列表-分页
    static async getList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getList(params)
        if (size === 0) {  // 不分页
            return data.data;
        } else { // 分页
            let list = CommonModel.generateListMybatisPlus(data.data)
            // 遍历config转换为对象
            let listData = list[0]
            return [listData, list[1]];
        }
    }

    // 通过条件获取列表-不分页
    static async conditionQueryList(name, schoolId) {
        let query = {
            name: name,
            schoolId: schoolId
        }
        let [data] = await conditionQueryList(query)
        return data.data
    }

    // 获取一个
    static async getOne(info) {
        let [res] = await getOne(info)
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }
}

export {AdministrationClazzModel}
