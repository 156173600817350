import {request_async} from "@/utils/requestAsync";
import { API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取列表
export async function getList(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/student/list`, "post_json", params);
}

// 新增
export async function save(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/student/save`, "post_json", params);
}

// 修改
export async function update(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/student/update`, "post_json", params);
}

// 删除
export async function remove(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/student/delete`, "post_json", params);
}

// 删除学生档案
export async function deleteStudentFile(studentid) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/student/deleteStudentFile/${studentid}`, "get", {});
}
