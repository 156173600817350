import {getList} from "@/api/exp/User";
import {CommonModel} from "@/model/CommonModel";

/**
 用户model
 */
class UserModel {
  // 获取列表-分页
  static async getList(page, size, query) {
    let params = {
      page: page,
      size: size,
    }
    params = Object.assign(params, query)
    let [data] = await getList(params)
    if (size === 0) {  // 不分页
      return data.data;
    } else { // 分页
      let list = CommonModel.generateListMybatisPlus(data.data)
      // 遍历config转换为对象
      let listData = list[0]
      return [listData, list[1]];
    }
  }
}

export {UserModel}
