<template>
  <div class="page-container">
    <!--班级名称-->
    <div class="page-title flex flex-between">
      <div class="left">
        <span class="dot"></span>
        <span
            class="title">{{
            `${$route.query['clazzName']}`
          }}</span>
      </div>
    </div>
    <!--筛选-->
    <div class="filter-container clearFix">
      <list-search-filter :search-filter="lists.searchFilter"
                          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)">
        <!--  操作  -->
        <div slot="right-container">
          <el-button type="primary" size="normal" @click="ListMethods().clickAddEntityBtn()">添加学生</el-button>
          <el-button type="default" size="small" @click="importStudent.dialog=true"><img src="@/assets/icon/import.png"
                                                                                         alt=""
                                                                                         style="width: 22px;height: 22px;margin-right: 5px;">批量导入学生
          </el-button>
          <el-button type="default" size="small" @click="ListMethods().clickExportBtn()"><img
              src="@/assets/icon/export.png" alt="" style="width: 22px;height: 22px;margin-right: 5px;">批量导出学生
          </el-button>
        </div>
      </list-search-filter>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column label="账号" prop="account" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.account }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex }}</span>
          </template>
        </el-table-column>
        <el-table-column label="行政班级" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.gradename + " - " + scope.row.administrationClazzName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round
                       @click="e=>ListMethods().clickDeleteBtn(scope.row,scope.$index)">移出本教学班
            </el-button>
            <el-button type="text" size="mini" round v-if="false"
                       @click="ListMethods().clickViewBtn(scope.row,scope.$index)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--详情弹窗-->
    <el-dialog
        :close-on-click-modal="false"
        :title="entityInfo.title"
        :visible.sync="entityInfo.dialog"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <!--搜索栏-->
        <div class="search-box flex flex-start">
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 100px">行政班级：</div>
            <el-autocomplete
                onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                v-model.trim="entityInfo.lists.query.administrationClazzName"
                :fetch-suggestions="(query,callback)=>EntityInfoMethods().clazzNameAutoSearch(query,callback)">
            </el-autocomplete>
          </div>
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 70px;">账 号：</div>
            <el-input v-model="entityInfo.lists.query.account"></el-input>
          </div>
          <el-button type="primary" @click="EntityInfoMethods().clickSearchBtn()">查找学生</el-button>
        </div>
        <!--列表栏-->
        <div class="lists-box" style="margin-top:30px ">
          <!--列表-->
          <div class="table-container">
            <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="entityInfo.lists.list"
                      max-height="500"
                      v-loading="entityInfo.lists.loading"
                      element-loading-text="加载中" fit
                      @selection-change="v=>EntityInfoMethods().onSelected(v)"
                      style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column label="账号" prop="account" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="性别" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.sex }}</span>
                </template>
              </el-table-column>
              <el-table-column label="行政班级" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.gradeName + " - " + scope.row.administrationClazzName }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--列表分页-->
          <div class="flex flex-between">
            <div style="margin-top: 5px">
              <span style="color:#409eff">已选{{ entityInfo.lists.selectedList.length }}项</span>
            </div>
            <div class="pagination-container">
              <el-pagination background @current-change="(number)=>EntityInfoMethods().pageChange(number)"
                             :current-page.sync="entityInfo.lists.pages.number"
                             :page-size.sync="entityInfo.lists.pages.size"
                             layout="total,prev, pager, next,sizes,jumper" :total="entityInfo.lists.pages.totalElements"
                             @size-change="(size)=>EntityInfoMethods().pageLimitChange(size)"
                             :page-count="entityInfo.lists.pages.totalPages">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="entityInfo.dialog=false">取 消</el-button>
        <el-button type="primary" :loading="entityInfo.actionAddDoing"
                   @click="EntityInfoMethods().clickSureBtn()">确 认</el-button>
      </span>
    </el-dialog>
    <!--导入input-->
    <input
        id="importFile"
        type="file"
        style="display: none"
        @change="(files)=>{ListMethods().importFileChange(files)}"
    >
    <!--学生导入弹窗-->
    <el-dialog
        title="批量导入学生"
        :visible.sync="importStudent.dialog"
        :close-on-click-modal="false"
        width="500px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form>
          <el-form-item label="导入模板(Excel):">
            <span style="margin-right: 15px">学生导入列表.xlsx</span>
            <el-button type="default" size="mini" @click="ListMethods().clickDownloadBtn()">下载</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default"
                   @click="importStudent.dialog=false">取 消
        </el-button>
        <el-button type="success"
                   @click="ListMethods().clickImportBtn()" :loading="importStudent.doing">导入学生
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * todo 按原型修改
 */
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_confirm_choose, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {date_format, downloadFile, find_obj_from_arr_by_id, objectToLVArr, validateMaxLength} from '@/utils/common'
import {mapState} from 'vuex'
import {StudentModel} from "@/model/exp/StudentModel";
import {CommonModel} from "@/model/CommonModel";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {UserModel} from "@/model/exp/UserModel";
import {AdministrationClazzModel} from "@/model/exp/AdministrationClazzModel";
import { read, utils } from "xlsx";

export default {
  name: 'teacherClazzInfo',
  components: {ListSearchFilter},
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    mobile(value) {
      if (value && value.length === 11) {
        let start = value.slice(0, 3)
        let end = value.slice(-4)
        return `${start}****${end}`
      }
      return ""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    // 校检账号
    const validateAccount = (rule, value, callback, maxLength, propName) => {
      if (!(value === 0 || value)) {
        callback(new Error('请输入' + propName))
        return
      }
      if (value.length > maxLength) {
        callback(new Error('最多输入' + maxLength + '个字，当前已输入' + value.length + "个字"))
      }
      // 特殊字符检测
      let regEn = /[`!#$%^&*()_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(value) || regCn.test(value)) {
        callback(new Error('仅支持数字、字母、部分符号'));
      }
      // 中文检测
      regCn = /[\u4e00-\u9fa5]+/g
      if (regCn.test(value)) {
        callback(new Error('仅支持数字、字母、部分符号'));
      }
      callback()
    }
    // 校检姓名
    const validateName = (rule, value, callback, maxLength, propName) => {
      if (!(value === 0 || value)) {
        callback(new Error('请输入' + propName))
        return
      }
      if (value.length > maxLength) {
        callback(new Error('最多输入' + maxLength + '个字，当前已输入' + value.length + "个字"))
      }
      // 特殊字符检测
      let regEn = /[`!#$%^&*()@_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(value) || regCn.test(value)) {
        callback(new Error('不支持特殊符号'));
      }
      // 仅支持中英文
      regEn = /[a-zA-Z]+/
      regCn = /[\u4e00-\u9fa5]+/g
      if (regEn.test(value) || regCn.test(value)) {
        // 判断是否有数字
        let regNumber = /\d/
        if (regNumber.test(value)) {
          callback(new Error('不能输入数字'));
        }
      } else {
        callback(new Error('仅支持中英文'));
      }
      callback()
    }
    return {
      clazzId: this.$route.query["clazzId"],
      // 外部方法
      date_format: date_format,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        pages: {
          size: 20
        },
        searchFilter: {
          search: [
            {
              type: 'input',
              label: '请输入学生姓名、账号',
              key: 'search',
              value: ''
            },
          ],
          filter: [
            {
              type: 'select',
              label: '行政班',
              key: 'administrationClazzId',
              hideTitle: true,
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        title: "添加学生",
        type: "add",
        dialog: false,
        actionAddDoing: false,
        lists: {
          selectedList: [],// 选择的列表
          list: [],
          loading: false,
          query: {},
          queryBase: {
            userType: "student",
          },
          pages: {
            size: 20
          },
        }
      },
      importStudent: {
        doing: false,
        dialog: false,
      }
    }
  },
  async mounted() {
    // 设置基础查询
    if (this.clazzId) {
      this.lists.queryBase = {
        clazzid: this.clazzId
      }
      this.ListMethods().getAdministrationClazzFilterList()
    }
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 生成行政班筛选列表
        async getAdministrationClazzFilterList() {
          let clazzInfo = await ClazzModel.getOne($this.clazzId)
          let options = [];// [{label:"","value":{}]
          let optionsObject = {};// {"value":"label"}
          let administrationClazzIdsArr = clazzInfo.administrationClazzIds.split("~")
          let administrationClazzNamesArr = clazzInfo.administrationClazzNames.split("~")
          // 新增全部
          options.push({
            label: "全部",
            value: ""
          })
          optionsObject[""] = "全部";
          administrationClazzIdsArr.forEach((li, index) => {
            options.push({
              label: administrationClazzNamesArr[index],
              value: li
            })
            optionsObject[li] = administrationClazzNamesArr[index];
          })
          $this.$set($this.lists.searchFilter.filter[0], "data", options)
          $this.$set($this.lists.searchFilter.filter[0], "dataObject", optionsObject)
        },
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          $this.$set($this.lists.query, "sort", "id,desc");
          [$this.lists.list, $this.lists.pages] = await StudentModel.getList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            $this.$set($this.lists.query, "sort", querySort)
            this.getList(1, $this.lists.pages.size, $this.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type) {
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.lists.pages.size, query)
          $this.lists.query = query
        },
        // 点击移出本班按钮
        async clickDeleteBtn(entity, index) {
          console.log(entity)
          if (await msg_confirm_choose("确认移出本教学班？", "移出本班", "取消", "确认移出", true) === "right") {
            if (await StudentModel.remove([entity.id], $this.clazzId)) {
              msg_success("移出成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("移出失败")
            }
          }
        },
        // 点击删除学生档案按钮
        async clickDeleteStudent(studentId) {
          if (await msg_confirm_choose("已删除档案的学生不再保留原学籍账号，原账号可对新入学学生使用。<br>" +
              "档案删除后无法恢复，确认删除？", "删除学生档案", "取消", "确认删除", true) === "right") {
            if (await StudentModel.deleteStudentFile(studentId)) {
              msg_success("删除学生档案成功")
              $this.entityInfo.dialog = false
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            }
          }
        },
        // 点击新增按钮
        clickAddEntityBtn() {
          $this.entityInfo.dialog = true;
          $this.EntityInfoMethods().getList(1, $this.entityInfo.lists.pages.size, $this.entityInfo.lists.query)
        },
        // 点击详情按钮
        clickViewBtn(entity) {
        },
        // 点击导出按钮
        async clickExportBtn() {
          let search = $this.lists.query.search
          let administrationClazzId = $this.lists.query.administrationClazzId
          StudentModel.export($this.clazzId, search, administrationClazzId)
        },
        // 点击了导入按钮
        clickImportBtn() {
          const uploader = document.getElementById('importFile')
          uploader.click()
        },
        // 导入excel预检测
        async excelCheck(file){
          return new Promise(function (resolve, reject) {
            // 安全增强-todo 读取excel文件，判断学校名称是不是这个学校？
            if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
              msg_err("上传格式不正确，请上传xls或者xlsx格式");
              reject(false)
              return
            }
            const fileReader = new FileReader();
            fileReader.onload = (ev) => {
              try {
                const data = ev.target.result;
                // 切换为新的调用方式
                const workbook = read(data, {
                  type: "binary",
                });
                // 取第一张表
                const wsname = workbook.SheetNames[0];
                // 切换为新的调用方式 生成json表格内容
                const ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                //console.log("处理之后的文件数据是：：", ws); //  这里为大家演示文件中的日期数据的处理
                for(let i=0;i<ws.length;i++){
                  // 1、检测学校名称是否是当前学校名称
                  if(ws[i]["*学校名称"]!=$this.userInfo.schoolname){
                    msg_err("第"+(i+1)+"行学校名称填写错误,,应该填写为 "+$this.userInfo.schoolname)
                    reject(false)
                    return;
                  }
                }
                resolve(true)
              } catch (e) {
                msg_err("表格处理错误！"+e);
                reject(false)
                return
              }
            };
            fileReader.readAsBinaryString(file);
          });
        },
        // 导入文件选择
        async importFileChange(files) {
          if (files.target.files.length <= 0) {
            msg_err("请选择一个xls或者xlsx格式文件");
            return
          }
          const file = files.target.files[0]
          document.getElementById('importFile').value = ''
          $this.importStudent.doing = true
          // 检测表格数据是否符合要求
          if(!await this.excelCheck(file).catch(e=>{
            $this.importStudent.doing = false
            return
          })){
            $this.importStudent.doing = false
            return
          }
          if (await StudentModel.import(file, $this.clazzId, $this.userInfo.schoolId)) {
            msg_success('导入学生成功!')
            $this.importStudent.dialog = false
            this.getList(1, $this.lists.pages.size, $this.lists.query)
          }else{// 导入失败

          }
          $this.importStudent.doing = false
        },
        // 点击下载导入列表按钮
        clickDownloadBtn() {
          downloadFile("https://resouce.cdzyhd.com/exp/20240429/%E5%AD%A6%E7%94%9F%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%88%97%E8%A1%A8_1.xlsx", "学生批量导入列表.xls")
        },
      }
    },
    // 实体Methods
    EntityInfoMethods() {
      let $this = this;
      return {
        // 行政班级名称自动搜索
        async clazzNameAutoSearch(query, callback) {
          let list = await AdministrationClazzModel.conditionQueryList(query, $this.userInfo.schoolId)
          let listResult = []
          list.forEach(li => {
            listResult.push({
              value: li.name
            })
          })
          callback(listResult)
        },
        // 点击搜索按钮
        clickSearchBtn() {
          this.getList(1, $this.entityInfo.lists.pages.size, $this.entityInfo.lists.query)
        },
        // 选中某些项
        onSelected(v) {
          $this.$set($this.entityInfo.lists, "selectedList", v);
        },
        // 获取列表
        async getList(page, size, query) {
          $this.entityInfo.lists.queryBase.schoolId = $this.userInfo.schoolId
          query = Object.assign(query, $this.entityInfo.lists.queryBase)
          $this.entityInfo.lists.loading = true;
          [$this.entityInfo.lists.list, $this.entityInfo.lists.pages] = await UserModel.getList(page, size, query)
          $this.entityInfo.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.entityInfo.lists.pages.size, $this.entityInfo.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.entityInfo.lists.pages.number, size, $this.entityInfo.lists.query)
        },
        // 点击确认按钮
        async clickSureBtn() {
          if ($this.entityInfo.lists.selectedList.length === 0) {
            msg_err("请选择要添加的学生")
            return
          }
          // 提交学生列表，构建数据
          let studentList = []
          $this.entityInfo.lists.selectedList.forEach((li, index) => {
            studentList.push({
              id: li.userid,// 21-11-10 必须是userid，才兼容v2.0版本数据
              schoolId: $this.userInfo.schoolId,
              clazzId: $this.clazzId,
              account: li.account,
              administrationClazzId: li.administrationClazzId
            })
          })
          console.log(studentList)
          $this.entityInfo.actionAddDoing = true
          if (await ClazzModel.addSelectStudent(studentList).catch(err => {
            $this.entityInfo.actionAddDoing = false
          })) {
            $this.entityInfo.actionAddDoing = false
            $this.entityInfo.dialog = false
            msg_success("添加学生成功")
            // 刷新列表
            $this.ListMethods().getList(1, $this.lists.pages.size, $this.lists.query)
          }
        },
      }
    }
  }
}
</script>

<style scoped lang="less">
.page-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 10px;

  .left {
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #2d7bc9;
      margin-right: 10px;
      display: inline-block;
    }

    .title {
      font-size: 15px;
      color: #666;
    }
  }

  .right {

  }
}
</style>
