import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_ADMIN} from "@/model/ConfigModel";

// 获取列表-分页
export async function getList(params) {
    return request_async(API_URL_EXP_ADMIN + `admin/administrationClazz/list`, "get", params);
}

// 通过条件获取列表-不分页
export async function conditionQueryList(params) {
    return request_async(API_URL_EXP_ADMIN + `admin/administrationClazz/conditionQueryList`, "get", params);
}

// 获取某一个
export async function getOne(id) {
    return request_async(API_URL_EXP_ADMIN + `admin/administrationClazz/info/` + id, "get", {});
}
