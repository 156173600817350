<template>
  <div class="list-filter">
    <div class="flex flex-start">
      <!--搜索-->
      <div class="search-container flex flex-start" v-if="searchFilter.search.length>0">
        <span class="title"></span>
        <div class="flex flex-start">
          <template v-for="(item,index) in searchFilter.search">
            <div class="fl">
              <el-input v-if="item.type==='input'" style="width: 200px;" class="search-item" size="small"
                        :placeholder="item.label" v-model="item.value">
              </el-input>
            </div>
          </template>
        </div>
      </div>
      <!--筛选和功能按钮-->
      <div class="filter-container flex flex-start" v-if="searchFilter.filter.length>0">
        <span class="title"></span>
        <div class="flex flex-start flex-wrap">
          <template v-for="(item,index) in searchFilter.filter" v-if="!item.hidden">
            <!--select类型的筛选-->
            <div class="filter-item" v-if="item.type==='select'">
              <span class="title" v-if="!item.hideTitle">{{item.label}}:</span>
              <el-select v-model="item.value" class="select" size="small"
                         @change="e=>item.change(item.data[e])">
                <el-option v-for="(item1,index1) in item.data" :key="index1" :value="item1.value"
                           :label="item1.label"></el-option>
              </el-select>
            </div>
            <!--timeRange类型的筛选-->
            <div class="filter-item" v-if="item.type==='timeRange'">
              <el-date-picker
                  v-model="item.value"
                  type="daterange"
                  :end-placeholder="item.label[1]"
                  :start-placeholder="item.label[0]"
                  @change="item.change"
                  :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--  搜索按钮  -->
    <div class="search-bottom flex flex-around">
      <div class="flex flex-start">
        <el-button class="button" plain icon="el-icon-delete"
                   @click="clickCleanBtn"
                   type="default"  size="small">重置
        </el-button>
        <el-button class="button" icon="el-icon-search"
                   @click="clickSearchBtn"
                   type="primary" size="small">查询
        </el-button>
      </div>
    </div>
    <div class="flex flex-end">
      <slot name="right-container"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "listSearchFilter",
  props: {
    searchFilter: {
      type: Object,
      default: {
        search: [],
        filter: []
      }
    }
  },
  data() {
    return {
      document: {},
    }
  },
  methods: {
    // 点击搜索按钮
    clickSearchBtn() {
      let query = {};
      // 遍历searchFilter
      this.searchFilter.search.forEach(li => {
        if (li.value===0||li.value) {
          if (li.format) {
            query[li.key] = li.format(li.value);
          } else {
            query[li.key] = li.value;
          }
        }
      });
      this.searchFilter.filter.forEach(li => {
        if (li.value===0||li.value) {
          if (li.format) {
            query = Object.assign(query, li.format(li.value));
          } else {
            query[li.key] = li.value;
          }
        }
      });
      // 回传
      this.$emit("clickSearchFilterBtn", query);
    },
    // 点击清空搜索按钮
    clickCleanBtn() {
      this.searchFilter.search.forEach(li => {
        if (li.value===0||li.value) {
          li.value = "";
        }
      });
      this.searchFilter.filter.forEach(li => {
        if (li.value===0||li.value) {
          li.value = ""
        }
      });
      // 2021.2.22 清空时直接搜索
      this.clickSearchBtn();
    },
  }
}
</script>

<style scoped rel="stylesheet/less" lang="less">
.title {
  font-size: 15px;
  color: #999;
}

.search-item, .filter-item {
  margin-right: 30px;
  span.title{
    font-size: 14px;
    margin-right: 10px;
  }
}

.select-append {
  float: left;
  margin-right: 5px;

  .select {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .button {
    margin-left: -6px;
    background-color: #f5f7fa;
    color: #939b9f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.search-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
